import { NextPageWithLayout } from "@bptypes/layout";
import ArtistPage from "@components/pages/artist/ArtistPage";
import MainLayout from "@layouts/MainLayout";
import { DEFAULT_LOCALE } from "@lib/constants";
import { exportAnonSession, useSessionContext } from "@lib/context/session";
import { getArtistQuery } from "@lib/network/artists";
import { getChartsQuery } from "@lib/network/charts";
import { getReleasesQuery } from "@lib/network/releases";
import { getArtistTopTracksQuery } from "@lib/network/tracks";
import { getLocationData } from "@lib/utils/getLocationData";
import type { Artist } from "@models/artist";
import { DehydratedState, QueryClient, dehydrate, useQuery } from "@tanstack/react-query";
import { GetServerSideProps, GetServerSidePropsContext, GetServerSidePropsResult } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { ReactElement } from "react";

interface Props {
	artist?: Artist;
	anonSession: AnonSession;
}

const Artist: NextPageWithLayout<Props> = ({ artist, anonSession }) => {
	const { getAccessToken, getIsSessionValid, importAnonSession } = useSessionContext();
	importAnonSession(anonSession);
	const accessToken = getAccessToken();
	const isSessionValid = getIsSessionValid();
	const isLoggedInSessionValid = getIsSessionValid({ isAnonAllowed: false });

	const { data: releases } = useQuery(
		{
			...getReleasesQuery({
				params: {
					artist_id: artist?.id.toString(),
					per_page: 30,
					order_by: "-release_date",
				},
				accessToken,
			}),
			enabled: isSessionValid,
		},
	);

	const { data: topTracks } = useQuery(
		{
			...getArtistTopTracksQuery({
				params: { per_page: 10 },
				accessToken,
				artistId: artist?.id || 0,
				number: 10,
			}),
			enabled: isSessionValid,
		},
	);

	const { data: charts } = useQuery(
		{
			...getChartsQuery({
				params: {
					dj_id: artist?.dj_association,
					per_page: 50,
				},
				accessToken,
				enabled: artist?.dj_association ? true : false,
			}),
			enabled: isLoggedInSessionValid,
		},
	);

	return (
		<ArtistPage
			artist={artist}
			releases={releases?.results || []}
			tracks={topTracks?.results || []}
			charts={charts?.results || []}
		/>
	);
};

export default Artist;

Artist.getLayout = (page: ReactElement<Props>) => {
	const artistImage: string = (page.props.artist?.image.uri || "");
	return (
		<MainLayout
			title={`${page.props.artist?.name} Music & Downloads on Beatport`}
			metaDescription={
				page.props.artist?.bio ||
				`${page.props.artist?.name} tracks and releases in highest quality ✚Find the latest releases here✚ #1 source for Livesets/ DJ Sets and more `
			}
			hasDesktopSidebar={true}
			noSuffix
			socialImage={artistImage}
		>
			{page}
		</MainLayout>
	);
};

type ServerSideProps = {
	dehydratedState: DehydratedState;
	anonSession: AnonSession;
	artist: Artist;
};

export const getServerSideProps: GetServerSideProps<ServerSideProps> = async (
	ctx: GetServerSidePropsContext,
): Promise<GetServerSidePropsResult<ServerSideProps>> => {
	const queryClient = new QueryClient();
	const { id } = ctx.params as { id: string };
	const artistId = id ? parseInt(id as string, 10) : 0;
	let artist: Artist | null = null;
	const location = getLocationData(ctx.req);

	if (artistId > 0) {
		const results = await Promise.all([
			queryClient
				.fetchQuery<Artist>(getArtistQuery({ artistId }))
				.then((res) => res)
				.catch(() => { }),
			queryClient
				.fetchQuery(
					getReleasesQuery({
						params: {
							artist_id: artistId.toString(),
							per_page: 30,
							order_by: "-release_date",
						},
						location,
					}),
				)
				.then((res) => res)
				.catch(() => { }),
			queryClient
				.fetchQuery(
					getArtistTopTracksQuery({
						params: { per_page: 10 },
						artistId,
						number: 10,
						location,
					}),
				)
				.then((res) => res)
				.catch(() => { }),
		]);

		if (results.length > 0) {
			artist = results[0] as Artist;
		}
	}

	const anonSession = await exportAnonSession();

	if (!artist) {
		return {
			notFound: true,
		};
	}

	return {
		props: {
			dehydratedState: dehydrate(queryClient),
			anonSession,
			...(await serverSideTranslations(ctx.locale ?? DEFAULT_LOCALE, ["translation"])),
			artist,
		},
	};
};
